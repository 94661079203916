import { welcomePageByModule } from "@src/config/routes";
import { appStore } from "@src/stores/AppStore";
import { useRouter } from "next/router";
import { Route } from "nextjs-routes";

const DEFAULT_ROUTE = "/users";

const Page = () => {
  const router = useRouter();

  let redirectTo: Route["pathname"] = DEFAULT_ROUTE;

  for (const [_module, route] of welcomePageByModule) {
    if (
      appStore.workspaceStore.hasModuleForPage(_module) &&
      appStore.authStore.hasPermissionForPage(_module)
    ) {
      redirectTo = route;
      break;
    }
  }

  router.replace(redirectTo);

  return null;
};

export default Page;
